import { render, staticRenderFns } from "./index.vue?vue&type=template&id=8fac46fc&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WDateRange from '@/components/WDateRange.vue'
import WPriceV2 from '@/components/WPriceV2.vue'
import WSelect from '@/components/WSelect.vue'
import WTopBar from '@/components/WTopBar.vue'
import WTotalSales from '@/components/WTotalSales.vue'
autoInstallComponents(component, {WDateRange,WPriceV2,WSelect,WTopBar,WTotalSales})
