<template>
  <div class="border border-gray-600 rounded overflow-hidden shadow">
    <w-top-bar :hide-menu="true" class="relative">
        <div class="flex justify-between ">
            <div>
                Estadísticas <span class="font-semibold">Cuentos en Pijamas</span>
            </div>
            <button @click="logout" class="absolute right-0 mr-5 flex rounded-md text-xs px-3 py-1 font-medium border border-red-500 text-red-800 bg-red-100">Salir</button>
        </div>
    </w-top-bar>
    <div class="h-full p-5 bg-gray-200">
      <div class="md:flex justify-between flex-wrap md:flex-nowrap">
        <div class="py-2 text-gray-900 flex gap-2">
             <div class="flex-1">
            <w-select label="Libros" class="z-20 md:w-48" v-model="skuFilter" :options="skuOptions" />
             </div><div class="flex-1 ">
            <w-select label="Moneda" class="z-20 md:w-24" v-model="currency" :options="currencies" />
            </div>
        </div>
        <div class="flex space-x-1 justify-center md:justify-right">
          <w-date-range v-model="dateFilter" @input="reload()" class="pt-1" />
        </div>
      </div>
      <div v-if="stats[currency]"
        class="flex flex-wrap space-y-3 md:flex-nowrap md:space-y-0 md:space-x-6"
      >
        <div class="relative w-full overflow-hidden bg-white rounded-lg shadow md:w-1/3">
          <div class="flex items-center py-4">
            <div>
              <svg
                class="w-6 h-6 m-4 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
                />
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="flex-grow text-xs leading-snug">
              <div class="font-medium text-gray-600">
                <span class="mr-1 font-semibold">{{ stats[currency].count }}</span
                >Ventas brutas
              </div>
              <div class="text-base font-semibold text-gray-900">
                <w-price-v-2 :currency="currency" :value="stats[currency].total" />
              </div>
            </div>
          </div>
          <div class="px-4 py-2 text-xs opacity-50 cursor-not-allowed bg-sand-500 text-base-500">
            Ver detalle
          </div>
        </div>

        <div class="w-full overflow-hidden bg-white rounded-lg shadow md:w-1/3">
          <div class="flex items-center py-4">
            <div>
              <svg
                class="w-6 h-6 m-4 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
                />
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="flex-grow text-xs leading-snug">
              <div class="font-medium text-gray-600">
                <div class="font-medium text-gray-600">
                  <span class="mr-1 font-semibold">{{ stats[currency].countToday }}</span
                  >Ventas de hoy
                </div>
              </div>
              <div class="text-base font-semibold text-gray-900">
                <w-price-v-2 :currency="currency" :value="stats[currency].today" />
              </div>
            </div>
          </div>
          <div class="px-4 py-2 text-xs opacity-50 cursor-not-allowed bg-sand-500 text-base-500">
            Ver detalle
          </div>
        </div>

        <div class="w-full overflow-hidden bg-white rounded-lg shadow md:w-1/3">
          <div class="flex items-center py-4">
            <div>
              <svg
                class="w-6 h-6 m-4 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
                />
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="flex-grow text-xs leading-snug">
              <div class="font-medium text-gray-600">Promedio por venta</div>
              <div class="text-base font-semibold text-gray-900">
                <w-price-v-2 :currency="currency" :value="averagePerSale" />
              </div>
            </div>
          </div>
          <div class="px-4 py-2 text-xs opacity-50 cursor-not-allowed bg-sand-500 text-base-500">
            Ver todo
          </div>
        </div>
      </div>
      <div class="mt-10">
        <div>
          <w-total-sales
            v-if="!loading"
            :loading="false"
            :chart="stats.totalsByDay"
            :currency="currency"
          />
          <!-- <pre class="text-xs">
            {{ stats.totalsByDay }}
        </pre
          > -->
        </div>
        <!-- <w-sales :items="sales" /> -->
      </div>
    </div>
  </div>
</template>

<script>
// import { makeFindMixin } from "feathers-vuex"; // Step 1
// import WPriceV2 from "../../components/WPriceV2.vue";
import moment from "moment";
import { mapActions } from 'vuex';

export default {
//   components: { WPriceV2 },
    layout:"plain",
  name: "Dashboard",
  data() {
    return {
        skuFilter: "todos",
        skuOptions: [

        { label: "Todos", key: "todos" },
        { label: "Cuentos en pijamas 1", key: ["CEP"] },
        { label: "Cuentos en pijamas 2 (De lujo)", key: ["CP2X"] },
        { label: "Cuentos en pijamas 2", key: ["CP2"] },
        ],

      dateFilter: {
        start: moment().subtract(10, "days").startOf("day").toDate(),
        end: moment().subtract(0, "days").endOf("day").toDate(),
      },
      currency: "ars",
      currencies: [
        { label: "USD", key: "usd" },
        { label: "ARS", key: "ars" },
      ],
      stats: {},
      loading: true
    };
  },
  async created() {
     this.reload()
     
  },
  watch: {
      skuFilter(skus){
          this.reload(skus);
      }
  },
  computed: {
    averagePerSale() {
      const totals = this.stats[this.currency];
      if(totals)
      return totals.total / totals.count;
      return 0
    },
  },
  methods: {
      ...mapActions("dashboard-stats", { get: "get" }),
    async reload(forceSkus) {
        let skus = forceSkus || this.skus
        
        const query = {
          start: this.dateFilter.start,
          end: this.dateFilter.end,
          key: "FVOJDAINGEOGVNVEG"
        }
        if(skus != "todos")
            query.skus = skus;

            console.log("Reload", query)

        this.loading = true
        this.stats = await this.get([1, {query}])
     this.loading = false;
    },
    logout(){
        localStorage.setItem('rememberMe', null)
        this.error = null;
        this.auth = null;
        window.location.reload()
    }
    
  },
};
</script>
